import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5b1e9894 = () => interopDefault(import('../pages/anggota-jdih/index.vue' /* webpackChunkName: "pages/anggota-jdih/index" */))
const _0cbf79a0 = () => interopDefault(import('../pages/beranda/index.vue' /* webpackChunkName: "pages/beranda/index" */))
const _6afb53a6 = () => interopDefault(import('../pages/berita/index.vue' /* webpackChunkName: "pages/berita/index" */))
const _e9ea2708 = () => interopDefault(import('../pages/buku/index.vue' /* webpackChunkName: "pages/buku/index" */))
const _43c63538 = () => interopDefault(import('../pages/dokumen/index.vue' /* webpackChunkName: "pages/dokumen/index" */))
const _042b10ee = () => interopDefault(import('../pages/galeri/index.vue' /* webpackChunkName: "pages/galeri/index" */))
const _ea671b24 = () => interopDefault(import('../pages/hubungi-kami/index.vue' /* webpackChunkName: "pages/hubungi-kami/index" */))
const _49e0b52b = () => interopDefault(import('../pages/katalog-perbup/index.vue' /* webpackChunkName: "pages/katalog-perbup/index" */))
const _3bdaed25 = () => interopDefault(import('../pages/katalog-perda/index.vue' /* webpackChunkName: "pages/katalog-perda/index" */))
const _5444a0e4 = () => interopDefault(import('../pages/page/index.vue' /* webpackChunkName: "pages/page/index" */))
const _7dfb3a68 = () => interopDefault(import('../pages/pengaduan/index.vue' /* webpackChunkName: "pages/pengaduan/index" */))
const _113e736c = () => interopDefault(import('../pages/pengumuman/index.vue' /* webpackChunkName: "pages/pengumuman/index" */))
const _9696932a = () => interopDefault(import('../pages/SOP/index.vue' /* webpackChunkName: "pages/SOP/index" */))
const _6284cc4f = () => interopDefault(import('../pages/statistik/index.vue' /* webpackChunkName: "pages/statistik/index" */))
const _77836b54 = () => interopDefault(import('../pages/struktur-organisasi/index.vue' /* webpackChunkName: "pages/struktur-organisasi/index" */))
const _4d5a5914 = () => interopDefault(import('../pages/tugas-pokok-fungsi-dan-program-kerja/index.vue' /* webpackChunkName: "pages/tugas-pokok-fungsi-dan-program-kerja/index" */))
const _05ff349c = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _465b1526 = () => interopDefault(import('../pages/visi-misi/index.vue' /* webpackChunkName: "pages/visi-misi/index" */))
const _5905bf46 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6944b25e = () => interopDefault(import('../pages/berita/_slug.vue' /* webpackChunkName: "pages/berita/_slug" */))
const _ed576998 = () => interopDefault(import('../pages/buku/_slug.vue' /* webpackChunkName: "pages/buku/_slug" */))
const _57b1e374 = () => interopDefault(import('../pages/page/_slug.vue' /* webpackChunkName: "pages/page/_slug" */))
const _14abb5fc = () => interopDefault(import('../pages/pengumuman/_slug.vue' /* webpackChunkName: "pages/pengumuman/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/anggota-jdih",
    component: _5b1e9894,
    name: "anggota-jdih"
  }, {
    path: "/beranda",
    component: _0cbf79a0,
    name: "beranda"
  }, {
    path: "/berita",
    component: _6afb53a6,
    name: "berita"
  }, {
    path: "/buku",
    component: _e9ea2708,
    name: "buku"
  }, {
    path: "/dokumen",
    component: _43c63538,
    name: "dokumen"
  }, {
    path: "/galeri",
    component: _042b10ee,
    name: "galeri"
  }, {
    path: "/hubungi-kami",
    component: _ea671b24,
    name: "hubungi-kami"
  }, {
    path: "/katalog-perbup",
    component: _49e0b52b,
    name: "katalog-perbup"
  }, {
    path: "/katalog-perda",
    component: _3bdaed25,
    name: "katalog-perda"
  }, {
    path: "/page",
    component: _5444a0e4,
    name: "page"
  }, {
    path: "/pengaduan",
    component: _7dfb3a68,
    name: "pengaduan"
  }, {
    path: "/pengumuman",
    component: _113e736c,
    name: "pengumuman"
  }, {
    path: "/SOP",
    component: _9696932a,
    name: "SOP"
  }, {
    path: "/statistik",
    component: _6284cc4f,
    name: "statistik"
  }, {
    path: "/struktur-organisasi",
    component: _77836b54,
    name: "struktur-organisasi"
  }, {
    path: "/tugas-pokok-fungsi-dan-program-kerja",
    component: _4d5a5914,
    name: "tugas-pokok-fungsi-dan-program-kerja"
  }, {
    path: "/video",
    component: _05ff349c,
    name: "video"
  }, {
    path: "/visi-misi",
    component: _465b1526,
    name: "visi-misi"
  }, {
    path: "/",
    component: _5905bf46,
    name: "index"
  }, {
    path: "/berita/:slug",
    component: _6944b25e,
    name: "berita-slug"
  }, {
    path: "/buku/:slug",
    component: _ed576998,
    name: "buku-slug"
  }, {
    path: "/page/:slug",
    component: _57b1e374,
    name: "page-slug"
  }, {
    path: "/pengumuman/:slug",
    component: _14abb5fc,
    name: "pengumuman-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
