const state = () => ({
  token: null,
  user: {},
  fcm: null,
  is_admin: false,

  checkout: {
    product: [
      {
        id : '',
        qty: 1
      }
    ],

    coupon_code: '',
    coupon_nominal: 0,

    used_date: new Date(),

    customer_info: {
      fullname: '',
      email: '',
      phone: ''
    },

    radio_check: 1,

    payment_info: {
      method: 'Transfer',
      detail:{
        bank_name: '',
        bank_rek_name: '',
        bank_rek_number: '',
        bank_is_active: 0,
        bank_image: ''
      }
    }
  }
})

const mutations = {
  setToken (state, token) {
    state.token = token
  },
  setUser (state, user) {
    state.user = user
  },
  setFcm (state, fcm) {
    state.fcm = fcm
  },
  setIsAdmin (state, is_admin) {
    state.is_admin = is_admin
  },

  setCheckout (state, checkout) {
    state.checkout = checkout
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}