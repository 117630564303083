const state = () => ({
  company: {
    color: [
      {
        hex: '#a20000',
        active: true
      },
      {
        hex: '#ffaaaa',
        active: false
      },
      {
        hex: '#2cb89a',
        active: false
      },
      {
        hex: '#73d9a2',
        active: false
      }
    ]
  },
  color: '#9E9E9E',
  company_name: 'JDIH Setwan Kabupaten Klaten',
  fav_icon: require('@/assets/logo/favicon.png'),
  logo: require('@/assets/logo/favicon.png')
})

const mutations = {
  setCompany (state, company) {
    state.company = company
  },
  setColor (state, color) {
    state.color = color
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}